module.exports = {
    siteUrl: `https://nightdreamer.me`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `Kevin's Blog - Softwareentwicklung, GameDev, 3D-Druck und IoT`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Ein persönlicher Blog über Softwareentwicklung (insbesondere Game Development), 3D-Druck und Internet of Things bzw. Projekte mit Mikroelektronik.`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Kevin's Blog`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#E9E9E9`, // Used for Offline Manifest
    themeColor: `#15171A`, // Used for Offline Manifest
}
